const biDataCenterRoute = [
  {
    path: '/biDataCenter/transactionStatistics',
    name: 'transactionStatistics',
    component: () => import('../views/biDataCenter/transactionStatistics.vue'),
    meta: {
      name: '交易统计',
      parentPath: '/biDataCenter/transactionStatistics',
      requireAuth: true
    }
  },
  {
    path: '/biDataCenter/transactionRecord',
    name: 'transactionRecord',
    component: () => import('../views/biDataCenter/transactionRecord.vue'),
    meta: {
      name: '交易记录',
      parentPath: '/biDataCenter/transactionRecord',
      keepAlive: true,
      requireAuth: true
    }
  }
]
export default biDataCenterRoute
