import { postRequest, deleteRequest, putRequest, getRequest } from './index'

// 获取担保列表   搜索
export const obtainGuaranteeCompany = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/guarantee/page',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
export const getGaranteeCompanyList = (
  successCallback,
  failureCallback
) => {
  getRequest(`/guarantee/list`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增   担保公司
export const addAndGuaranteeCompany = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/guarantee/add',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 编辑  担保公司
export const putGuaranteeCompany = (
  data,
  successCallback,
  failureCallback
) => {
  putRequest(
    '/guarantee/edit',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 删除  担保公司
export const deleteGuaranteeCompany = (id, successCallback, failureCallback) => {
  deleteRequest(`/guarantee/delete/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

