const hangOutManagementRoute = [
  {
    path: '/hangOutManagement/index',
    name: 'hangOutManagementIndex',
    component: () => import('../views/hangOutManagement/index.vue'),
    meta: {
      name: '挂牌管理',
      parentPath: '/hangOutManagement/index',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/hangOutManagement/hangOutDetails',
    name: 'hangOutDetails',
    component: () => import('../views/hangOutManagement/children/hangOutDetails.vue'),
    meta: {
      name: '挂牌详情',
      parentPath: '/hangOutManagement/index',
      requireAuth: true
    }
  }
]
export default hangOutManagementRoute
