import { getRequest, postRequest } from './index'
import axios from 'axios'
import { baseURL } from '../systemConfiguration/index'
// 获取字典数据
export const getSelectAllDict = new Promise((resolve, reject) => {
  getRequest('/dataDict/selectAllDict', {}, res => (
    resolve(res)
  ), error => {
    reject(error)
  })
})
// 获取省市区
export const getSelectAreaTree = (areaType, successCallback, failureCallback) => {
  getRequest('/dataDict/selectAreaTree?areaType=' + areaType, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取验证码
export const getCodes = (successCallback, failureCallback) => {
  getRequest('/sys/captcha', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 登录接口
export const login = (data, successCallback, failureCallback) => {
  postRequest('/sys/login', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 退出登录接口
export const loginOut = (data, successCallback, failureCallback) => {
  postRequest('/sys/logout', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 修改密码
export const updatePassword = (data, successCallback, failureCallback) => {
  postRequest('/user/updatePassword', { params: data }, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 运算函数管理
// 运算类型：1-加、2-减、3-乘、4-除(四舍五入)
export const operationMethods = (data, successCallback, failureCallback) => {
  getRequest(`/math/operation?type=${data.type}&param1=${data.param1}&param2=${data.param2}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 发送手机验证码
export const sendPhoneCode = (data, successCallback, failureCallback) => {
  postRequest(`/abc/bailPay/sendVerifyCode`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// export const sendPhoneCode = (phone, successCallback, failureCallback) => {
//   postRequest('/cmp/sendCode?phone=' + phone, {}, res => (
//     successCallback(res)
//   ), error => {
//     if (failureCallback)failureCallback(error)
//   })
// }
// 获取首页的统计数
export const getUserAdminRoleCount = (data, successCallback, failureCallback) => {
  postRequest(`user/getUserAdminRoleCount`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 生成验证码图片
export const generateCaptchaImage = (data) => {
  return new Promise((resolve, reject) => {
    postRequest(`sys/generateCaptchaImage`, data, res => {
      resolve(res.data)
    }, error => {
      reject(error)
    })
  })
}

// 校验验证码图片
export const validCaptchaImage = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}sys/validCaptchaImage`, data).then((res) => {
      resolve(res.data)
    }, error => {
      reject(error)
    })
  })
}
