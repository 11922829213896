/**
 * 系统管理 用户管理
 */
<template>
  <div class="userPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="searchFormItemArr" :reset="false">
      <!-- <el-form-item label="机构" prop="orgcodeName" label-width="84px">
        <el-select ref="treeSelect" v-model="formInline.orgcodeName" class="br-select tree-select" :popper-append-to-body="false" placeholder="请选择机构">
          <el-option value="null">
            <el-tree :data="organizationByManList" node-key="id" :props="defaultProps" @node-click="onLeavesClick" />
          </el-option>
        </el-select>
      </el-form-item> -->
    </FormSearch>
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="handleEdit">
          添加
        </el-button>
      </div>
      <Table
        :operation-width="240"
        :item-data="itemData"
        :list-data="userData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页组件 -->
      <Pagination :params="formInline" :total="total" :in-article="userData.length" :get-data-list="getdata" />
    </div>
    <!-- 编辑界面 -->
    <el-dialog
      :title="title"
      :visible.sync="editFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @click="closeDialog('edit')"
    >
      <el-form ref="editForm" label-width="80px" :model="editForm" :rules="rules">
        <el-form-item label="登录账号" prop="userCode">
          <el-input
            v-model="editForm.userCode"
            size="small"
            :disabled="editForm.password ? true : false"
            auto-complete="off"
            placeholder="请输入英文或数字"
          />
        </el-form-item>
        <el-form-item label="真实姓名" prop="userName">
          <el-input v-model="editForm.userName" size="small" auto-complete="off" placeholder="请输入真实姓名" />
        </el-form-item>
        <el-form-item label="角色" prop="roleCode">
          <div class="authorize">
            <i v-if="editForm.roleCode.length > 0" class="el-icon-success" /> <span
              v-else
              @click="getRole"
            >去授权</span><span v-if="editForm.roleCode.length > 0" @click="getRole">查看授权</span>
          </div>
        </el-form-item>
        <el-form-item label="部门" prop="subOrgCode">
          <el-select v-model="editForm.subOrgCode" placeholder="请选择">
            <el-option v-for="item in organizationArr" :key="item.orgCode" :label="item.orgName" :value="item.orgCode" />
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phoneNo">
          <el-input v-model="editForm.phoneNo" size="small" placeholder="请输入手机号" />
        </el-form-item>
        <!-- <el-form-item label="邮箱">
          <el-input size="small" v-model="editForm.email" placeholder="请输入邮箱地址"></el-input>
        </el-form-item> -->
        <el-form-item label="性别" prop="sex">
          <el-radio v-model="editForm.sex" label="0">
            男
          </el-radio>
          <el-radio v-model="editForm.sex" label="1">
            女
          </el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain class="widen-button" @click="closeDialog('edit')">
          取消
        </el-button>
        <!-- <el-button size="small" @click="Form')" type="success" plain>重置</el-button> -->
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          class="title widen-button"
          @click="submitForm('editForm')"
        >
          保存
        </el-button>
      </div>
    </el-dialog>
    <!-- 获取角色的 -->
    <GetRole ref="getRole" :edit-check-role-list="editForm.roleCode" @getCheckRole="getCheckRole" />
  </div>
</template>

<script>
// 导入请求方法
import { getOrganizationList, userEnabledAndStop, getAllRoles, userResetPassword, addAndUpdataUser, listPageBySystem, getOrganizationByMan } from '../../api/systemManagement'
import Pagination from '../../components/Pagination2'
import FormSearch from '../../components/FormSearch'
import Table from '@/components/Table'
import GetRole from './components/getRole.vue'
import { formPattern } from '@/utils/matchFormRegex'
export default {
  // 注册组件
  components: {
    Pagination,
    FormSearch,
    Table,
    GetRole
  },
  data() {
    var validateUserCode = (rule, value, callback) => {
      if (Number(value)) {
        callback(new Error('里面必须含有字母'))
        return
      }
      callback()
    }
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      organizationByManList: [],
      roleArr: [],
      organizationArr: [],
      total: 0,
      loading: false, // 是显示加载
      title: '添加用户',
      editFormVisible: false, // 控制编辑页面显示与隐藏
      // 编辑与添加
      editForm: {
        roleCode: []
      },
      // 选择数据
      selectdata: [],
      // rules表单验证
      rules: {
        userName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' }
        ],
        subOrgCode: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ],
        userCode: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          { ...formPattern.letterNumberPlaces },
          { validator: validateUserCode, trigger: 'blur' },
          { min: 5, max: 25, message: '长度在 5 到 25 个字符', trigger: 'blur' }
        ],
        roleCode: [{ required: true, message: '角色不能为空', trigger: 'change' }],
        phoneNo: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1(3\d|47|5((?!4)\d)|7(0|1|[6-8])|8\d)\d{8,8}$/,
            required: true,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            required: true,
            message: '请输入正确的邮箱',
            trigger: 'blur'
          }
        ]
      },
      // 请求数据参数
      formInline: {
        pageNum: 1,
        pageSize: 10
      },
      // 用户数据
      userData: [],
      searchFormItemArr: [
        { type: 'input', label: '登录账号', value: 'userCode' },
        { type: 'input', label: '真实姓名', value: 'userName' },
        { type: 'input', label: '手机号', value: 'phoneNo' },
        // { type: 'input', label: '邮箱', value: 'email' },
        { type: 'select', label: '角色', value: 'roleCodeArr', pLabel: 'roleName', pValue: 'roleCode', child: [] }
        // { type: 'select', label: '部门', value: 'orgCode', pLabel: 'orgName', pValue: 'orgCode', child: [] }
      ],
      itemData: [
        { label: '登录账号', prop: 'userCode', width: 200 },
        { label: '真实姓名', prop: 'userName', width: 120 },
        { label: '手机号', prop: 'phoneNo', width: 120 },
        // { label: '部门', prop: 'orgName', width: 260 },
        { label: '角色', prop: 'roleName', width: 400 },
        { label: '性别', prop: 'sex', child: [{ value: '0', label: '男' }, { value: '1', label: '女' }], width: 80 },
        { label: '状态', prop: 'isLock', child: [{ value: '1', label: '停用' }, { value: '0', label: '启用' }], width: 100 }
        // { label: '邮箱', prop: 'email', width: 180 }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.handleEdit },
        { bType: 'primary', label: '重置密码', handleEvent: this.resetpwd },
        { val: 'isLock', num: '0', bType: 'danger', label: '停用', handleEvent: this.offlineUser, withParameters: 1 },
        { val: 'isLock', num: '1', bType: 'success', label: '启用', handleEvent: this.offlineUser, withParameters: 0 }
      ]
    }
  },
  created() {
    getAllRoles('00', res => {
      this.searchFormItemArr[3].child = this.roleArr = res.data
    })
    getOrganizationList(res => {
      this.getDepartment(res.data)
      // this.searchFormItemArr[5].child = [...this.organizationArr]
    })
    // 组织
    this.getOrganizationByMan()
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    getOrganizationByMan() {
      getOrganizationByMan({}, () => {
      })
    },
    onLeavesClick(data, node, el) {
    // if (node.isLeaf) {
      // this.formParams.orgcodeName = data.label
      // this.formParams.orgCode = data.id
      // this.$refs.treeSelect['handleClose']()
    // }
    },
    // 递归取部门管理的部门
    getDepartment(arr) {
      if (!arr || arr.length === 0) return
      arr.forEach(item => {
        this.organizationArr.push(item)
        if (item.children && item.children.length > 0) {
          this.getDepartment(item.children)
        }
      })
    },
    // 打开角色弹出层
    getRole() {
      this.$refs.getRole.authorityManagementVisible = true
      this.editForm.roleCode = [...this.editForm.roleCode]
    },
    // 获取角色的数
    getCheckRole(value) {
      this.editForm.roleCode = [...value]
    },
    // 获取数据方法
    getdata(type) {
      if (!type) this.formInline.pageNum = 1
      listPageBySystem(this.formInline, res => {
        this.total = res.data.total
        this.userData = [...res.data.pageData]
      })
    },
    // 显示编辑界面
    handleEdit: function(row) {
      this.editFormVisible = true
      if (row.constructor === Object) {
        this.title = '编辑用户'
        this.editForm = { ...row }
        delete this.editForm.createTime
        delete this.editForm.modifyTime
      } else {
        this.title = '添加用户'
        this.editForm = {
          roleCode: []
        }
      }
    },
    // 编辑、添加提交方法
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          this.loading = true
          // 这个操作是判断他点击编辑的时候没有去操作角色就自动给他赋值成下面那种形式  因为后端需要那种  但是他返回的数据不是那种
          const arr = []
          this.editForm.roleCode.forEach(item => {
            if (item.constructor === Object) {
              arr.push(item)
            } else {
              arr.push({ roleCode: item, usePlatform: '00' })
            }
          })
          this.editForm.orgCode = JSON.parse(localStorage.getItem('userdata')).cmpId
          // 请求方法
          addAndUpdataUser({ ...this.editForm, roleCode: [...arr] }, () => {
            this.loading = false
            this.editFormVisible = false
            this.$message.success('成功')
            this.getdata(true)
          }, () => {
            this.loading = false
          })
        }
      })
    },
    // 关闭编辑、增加弹出框
    closeDialog(dialog) {
      if (dialog === 'edit') {
        this.editFormVisible = false
      } else if (dialog === 'perm') {
        this.dataAccessshow = false
      } else if (dialog === 'unit') {
        this.unitAccessshow = false
      }
      this.$refs['editForm'].clearValidate()
    },
    // 重置密码
    resetpwd(row) {
      this.$confirm('确定要重置密码吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          userResetPassword(row.userCode, () => {
            this.$message.success('重置密码成功！')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消重置密码！'
          })
        })
    },
    // 下线用户
    offlineUser(row, index) {
      this.$confirm(`确定要让${row.userName}用户${index === 1 ? '停用' : '启用'}吗?`, '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        userEnabledAndStop({ params: { userCode: row.userCode, isLock: index }}, res => {
          this.getdata(true)
          this.$message.success(index === 0 ? '启用成功' : '停用成功')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style lang="scss">

.userPageBox {
  .authorize {
    color: $assistColor4;

    i {
      color: $assistColor4;
      margin-right: 12px;
    }

    span {
      color: $assistColor4;
      cursor: pointer;
    }
  }

  .user-search {
    margin-top: 20px;
  }

  .user-search .el-input,
  .user-search .el-select {
    width: 180px;
  }

  .userRole {
    width: 100%;
  }
  .tree-select {
  .el-select-dropdown__item {
    height: auto;
    overflow: hidden;
    &.hover,
    &:hover {
      background-color: #fff;
    }
  }
}
}
</style>

