
let SRCURL = ''
let baseURL = ''
let scfURL = ''
let cmsURL = ''
let monitorURL = ''
let IMGPATH = 'http://120.77.45.206/images/'
let SCMAPI = ''// 集采地址
let PIAOJUURL = '' // 票据
let projectUrl = '' // 商城前台地址
switch (true) {
  case location.href.indexOf('47.104.208.84') >= 0: // 开发环境
    SRCURL = `https://api.369tecgroup.com/scm/v1`
    baseURL = `https://api.369tecgroup.com/plat/approveabc/`
    scfURL = `https://api.369tecgroup.com/scf/approveabc/`
    cmsURL = `https://api.369tecgroup.com/cms/approveabc`
    monitorURL = `https://api.369tecgroup.com/monitor/api/v1`
    IMGPATH = `https://image.369tecgroup.com/images/`
    SCMAPI = `https:///api.369tecgroup.com/scm/api/`
    PIAOJUURL = `https://api.369tecgroup.com/pjht/operate/`
    projectUrl = `https://b2b.369tecgroup.com/#/`
    break
  case location.href.indexOf('369tecgroup') >= 0: // 开发环境
    SRCURL = `https://api.369tecgroup.com/scm/v1`
    baseURL = `https://api.369tecgroup.com/plat/approveabc/`
    scfURL = `https://api.369tecgroup.com/scf/approveabc/`
    cmsURL = `https://api.369tecgroup.com/cms/approveabc`
    monitorURL = `https://api.369tecgroup.com/monitor/api/v1`
    IMGPATH = `https://image.369tecgroup.com/images/`
    SCMAPI = `https://api.369tecgroup.com/scm/api/`
    PIAOJUURL = `https://api.369tecgroup.com/pjht/operate/`
    projectUrl = `https://b2b.369tecgroup.com/#/`
    break
  case location.href.indexOf('120.77.45.206') >= 0: // 测试环境
    SRCURL = `http://120.77.45.206:8086/scm/v1`
    baseURL = `http://120.77.45.206:8086/plat/approveabc/`
    scfURL = `http://120.77.45.206:8086/scf/approveabc/`
    cmsURL = `http://120.77.45.206:8086/cms/approveabc`
    monitorURL = `http://120.77.45.206:8086/monitor/api/v1`
    SCMAPI = `http://120.77.45.206:8086/scm/api/`
    PIAOJUURL = `http://120.77.45.206:8086/pjht/operate/`
    projectUrl = `http://120.77.45.206/scm-portal-test/#/`
    break
  case location.href.indexOf('120.25.107.214') >= 0: // 之前测试环境  兼容之前的变成开发环境；了
    SRCURL = `http://120.25.107.214:8086/scm/v1`
    baseURL = `http://120.25.107.214:8086/plat/approveabc/`
    scfURL = `http://120.25.107.214:8086/scf/approveabc/`
    cmsURL = `http://120.25.107.214:8086/cms/approveabc`
    monitorURL = `http://120.25.107.214:8086/monitor/api/v1`
    SCMAPI = `http://120.25.107.214:8086/scm/api/`
    PIAOJUURL = `http://120.25.107.214:8086/pjht/operate/`
    projectUrl = `http://120.25.107.214/scm-portal-dev/#/`
    break
  case location.pathname.indexOf('-pre') >= 0: // pre测试环境
    SRCURL = `http://112.74.164.238:8086/scm/v1`
    baseURL = `http://112.74.164.238:8086/plat/approveabc/`
    scfURL = `http://112.74.164.238:8086/scf/approveabc/`
    cmsURL = `http://112.74.164.238:8086/cms/approveabc`
    monitorURL = `http://112.74.164.238:8086/monitor/api/v1`
    SCMAPI = `http://112.74.164.238:8086/scm/api/`
    PIAOJUURL = `http://112.74.164.238:8086/pjht/operate/`
    break
  default:
    // http://192.168.110.59:8086 闫鹏
    // 192.168.110.175:8081 建华 http://192.168.110.175:9092 保理融资
    // 192.168.110.77:8086
    IMGPATH = `http://120.77.45.206/images/`
    SRCURL = `http://120.25.107.214:8086/scm/v1`
    baseURL = `http://120.25.107.214:8086/plat/approveabc/`
    scfURL = `http://120.25.107.214:8086/scf/approveabc`
    cmsURL = `http://120.25.107.214:8086/cms/approveabc`
    monitorURL = `http://120.25.107.214:8086/monitor/api/v1`
    SCMAPI = `http://120.25.107.214:8086/scm/api/`
    PIAOJUURL = `http://120.25.107.214:8086/pjht/operate/`
    projectUrl = `http://120.25.107.214/scm-portal-dev/#/`
    break
}
// 角色类型
const roleType = [
  { id: '222', name: '业务类' },
  { id: '221', name: '管理类' }
]
// 公共角色平台
const rolePlatform = [
  { id: '00', name: '默认自己所属企业' },
  { id: '0101', name: '核心企业' },
  { id: '0102', name: '融资企业' },
  { id: '02', name: '资金方' }
]
// 权限类型
const qType = [
  { id: 1, name: '栏目' },
  { id: 2, name: '菜单' },
  { id: 3, name: '按钮' }
]
// 机构
const orgCodeArr = [
  { id: '00000', name: '产融链平台' },
  { id: '0000', name: '平台方' },
  { id: '10024', name: '资金方' },
  { id: '01', name: '核心企业' },
  { id: '02', name: '融资企业' },
  { id: '03', name: '核心企业&融资企业' }
]
// 新闻类型
const newsType = [
  { id: 1, name: '公司新闻' },
  { id: 2, name: '行业动态' }
]
// 操作类型
const operationType = [
  { id: 0, name: '登录' },
  { id: 1, name: '新增' },
  { id: 2, name: '修改' },
  { id: 3, name: '删除' }
]
// 业务类型
const businessType = [
  { id: 1, name: '登录模块' },
  { id: 2, name: '产品模块' },
  { id: 3, name: '额度模块' },
  { id: 4, name: '融资模块' }
]
// 企业角色
const enterpriseRole = [
  { id: '01', name: '核心企业' },
  { id: '02', name: '供应商' },
  { id: '03', name: '经销商' },
  { id: '04', name: '代采商' }
]
// 账户状态
const accountStatus = [
  { id: '01', name: '待补录' },
  { id: '02', name: '待平台审核' },
  { id: '-1', name: '已驳回' },
  { id: '99', name: '审核通过' },
  { id: '04', name: '平台终审' }
]
// 注册来源
const registeredSource = [
  { id: '00', name: '平台代理' },
  { id: '01', name: '门户商城' }
]
// 录入方
const intoParty = [
  { id: '00', name: '企业方' },
  { id: '01', name: '平台方' }
]
export {
  SCMAPI,
  SRCURL,
  baseURL,
  scfURL,
  cmsURL,
  monitorURL,
  IMGPATH,
  PIAOJUURL,
  projectUrl,
  intoParty,
  registeredSource,
  accountStatus,
  enterpriseRole,
  businessType,
  operationType,
  newsType,
  roleType,
  orgCodeArr,
  rolePlatform,
  qType
}
