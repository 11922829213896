const transactionDataRouter = [
  {
    path: '/transactionData/transactionDataManagement',
    name: 'transactionDataManagement',
    component: () => import('../views/transactionData/transactionDataManagement.vue'),
    meta: {
      name: '交易订单',
      keepAlive: true,
      parentPath: '/transactionData/transactionDataManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/transactionDataManagementDetails',
    name: 'transactionDataManagementDetails',
    component: () => import('../views/transactionData/children/transactionDataManagementDetails.vue'),
    meta: {
      name: '交易订单详情',
      parentPath: '/transactionData/transactionDataManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/transactionDataPut',
    name: 'transactionDataPut',
    component: () => import('../views/transactionData/transactionDataPut.vue'),
    meta: {
      name: '交易数据入库',
      keepAlive: true,
      parentPath: '/transactionData/transactionDataPut',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/transactionDataPutDetails',
    name: 'transactionDataPutDetails',
    component: () => import('../views/transactionData/children/transactionDataPutDetails.vue'),
    meta: {
      name: '交易数据入库详情',
      parentPath: '/transactionData/transactionDataPut',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/transactionDataOut',
    name: 'transactionDataOut',
    component: () => import('../views/transactionData/transactionDataOut.vue'),
    meta: {
      name: '交易数据出库',
      keepAlive: true,
      parentPath: '/transactionData/transactionDataOut',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/transactionDataOutDetails',
    name: 'transactionDataOutDetails',
    component: () => import('../views/transactionData/children/transactionDataOutDetails.vue'),
    meta: {
      name: '交易数据出库详情',
      parentPath: '/transactionData/transactionDataOut',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/transactionDataInvoice',
    name: 'transactionDataInvoice',
    component: () => import('../views/transactionData/transactionDataInvoice.vue'),
    meta: {
      name: '交易发票管理',
      keepAlive: true,
      parentPath: '/transactionData/transactionDataInvoice',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/transactionDataInvoiceDetails',
    name: 'transactionDataInvoiceDetails',
    component: () => import('../views/transactionData/children/transactionDataInvoiceDetails.vue'),
    meta: {
      name: '交易发票管理详情',
      parentPath: '/transactionData/transactionDataInvoice',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/transactionDataMoney',
    name: 'transactionDataMoney',
    component: () => import('../views/transactionData/transactionDataMoney.vue'),
    meta: {
      name: '交易数据资金',
      keepAlive: true,
      parentPath: '/transactionData/transactionDataMoney',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/transactionDataMoneyDetails',
    name: 'transactionDataMoneyDetails',
    component: () => import('../views/transactionData/children/transactionDataMoneyDetails.vue'),
    meta: {
      name: '交易数据资金详情',
      parentPath: '/transactionData/transactionDataMoney',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/purchasingManagement',
    name: 'purchasingManagement',
    component: () => import('../views/transactionData/purchasingManagement.vue'),
    meta: {
      name: '采购管理',
      keepAlive: true,
      parentPath: '/transactionData/purchasingManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/addPurchasing',
    name: 'addPurchasing',
    component: () => import('../views/transactionData/children/addPurchasing.vue'),
    meta: {
      name: '新增采购订单',
      parentPath: '/transactionData/purchasingManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/editPurchasing',
    name: 'editPurchasing',
    component: () => import('../views/transactionData/children/addPurchasing.vue'),
    meta: {
      name: '编辑采购订单',
      parentPath: '/transactionData/purchasingManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/purchasingDetails',
    name: 'purchasingDetails',
    component: () => import('../views/transactionData/children/purchasingDetails.vue'),
    meta: {
      name: '采购订单详情',
      parentPath: '/transactionData/purchasingManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/marketManagement',
    name: 'marketManagement',
    component: () => import('../views/transactionData/purchasingManagement.vue'),
    meta: {
      name: '销售管理',
      keepAlive: true,
      parentPath: '/transactionData/marketManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/addMarket',
    name: 'addMarket',
    component: () => import('../views/transactionData/children/addPurchasing.vue'),
    meta: {
      name: '新增销售订单',
      parentPath: '/transactionData/marketManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/editMarket',
    name: 'editMarket',
    component: () => import('../views/transactionData/children/addPurchasing.vue'),
    meta: {
      name: '编辑销售订单',
      parentPath: '/transactionData/marketManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/marketDetails',
    name: 'marketDetails',
    component: () => import('../views/transactionData/children/purchasingDetails.vue'),
    meta: {
      name: '销售订单详情',
      parentPath: '/transactionData/marketManagement',
      requireAuth: true
    }
  },
  {
    path: '/transactionData/tradingEnterprise',
    name: 'tradingEnterprise',
    component: () => import('../views/transactionData/tradingEnterprise.vue'),
    meta: {
      name: '交易企业',
      keepAlive: true,
      parentPath: '/transactionData/tradingEnterprise',
      requireAuth: true
    }
  }
]
export default transactionDataRouter
