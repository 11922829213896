const basicSettingRoute = [
  {
    path: '/basicSetting/workflowConfiguration',
    name: 'workflowConfiguration',
    component: () => import('../views/basicSetting/workflowConfiguration.vue'),
    meta: {
      name: '流程配置',
      parentPath: '/basicSetting/workflowConfiguration',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/basicSetting/processMap',
    name: 'processMap',
    component: () => import('../views/basicSetting/children/processMap.vue'),
    meta: {
      name: '流程绘制',
      parentPath: '/basicSetting/workflowConfiguration',
      requireAuth: true
    }
  },
  {
    path: '/basicSetting/tradingHour',
    name: 'tradingHour',
    component: () => import('../views/basicSetting/tradingHour.vue'),
    meta: {
      name: '交易时间',
      parentPath: '/basicSetting/tradingHour',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/basicSetting/tradeDate',
    name: 'tradeDate',
    component: () => import('../views/basicSetting/tradeDate.vue'),
    meta: {
      name: '交易日期',
      keepAlive: true,
      parentPath: '/basicSetting/tradeDate',
      requireAuth: true
    }
  }
]
export default basicSettingRoute
