<template>
  <div class="page-info-content">
    <div>
      <el-button type="primary" class="high-button" @click="operationTree">
        {{ !expandAll ? '全部展开' : '全部收缩' }}
      </el-button>
      <!-- 选择平台方 -->
      <el-select v-model="roleTypeId" class="elSelect" placeholder="请选择" @change="initList">
        <el-option v-for="item in roleTypeOptions" :key="item.dictId" :label="item.dictName" :value="item.dictId" />
      </el-select>
    </div>
    <div class="Permission">
      <!-- 树结构渲染列表数据 -->
      <div class="page-tree">
        <el-tree
          ref="tree"
          :data="datas"
          node-key="resCode"
          :default-expand-all="expandAll"
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
        >
          <span slot-scope="{ node, data }" class="custom-tree-node">
            <span>{{ data.resName }}</span>
            <span>
              <el-button type="text" size="mini" @click="() => append(data)">新增</el-button>
              <el-button v-if="data.resPcode" type="text" size="mini" @click="() => remove(node, data)">删除</el-button>
            </span>
          </span>
        </el-tree>
      </div>
      <!-- 右边编辑框 -->
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="权限名称" prop="resName">
          <el-input v-model="ruleForm.resName" size="small" auto-complete="off" placeholder="权限名称" />
        </el-form-item>
        <el-form-item label="权限CODE" prop="resCode">
          <el-input v-model="ruleForm.resCode" size="small" disabled auto-complete="off" placeholder="权限CODE" />
        </el-form-item>
        <el-form-item label="排序" prop="orders">
          <el-input
            v-model="ruleForm.orders"
            size="small"
            auto-complete="off"
            placeholder="请输入数字,数字越小越靠前"
            type="number"
          />
        </el-form-item>
        <el-form-item label="权限类型" prop="resOperType">
          <el-select v-model="ruleForm.resOperType" size="small" filterable placeholder="请选择权限类型">
            <el-option v-for="item in qType" :key="item.id" :label="item.name" :value="`${item.id}`" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.resOperType == '2'" label="权限URL" prop="resUrl">
          <el-input v-model="ruleForm.resUrl" size="small" auto-complete="off" placeholder="权限URL" />
        </el-form-item>
        <el-form-item v-if="ruleForm.resOperType == '2' || ruleForm.resOperType == '1'" label="icon" prop="icon">
          <el-input v-model="ruleForm.icon" size="small" auto-complete="off" placeholder="icon" />
        </el-form-item>
        <el-form-item label="上级">
          <el-cascader
            v-model="ruleForm.resPcode"
            :options="datas"
            :props="{ emitPath: false, label: 'resName', value: 'resCode', checkStrictly: true }"
            clearable
            placeholder="不选默认为平台第一级"
          />
        </el-form-item>
        <el-form-item label="资源系统类型" prop="resSysType">
          <el-select v-model="ruleForm.resSysType" size="small" filterable placeholder="请选择资源系统类型">
            <el-option v-for="item in $store.getters.getDictionaryItem('RES_SYS_TYPE')" :key="item.dictId" :label="item.dictName" :value="item.dictId" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="资源类型">
          <el-select size="small" filterable v-model="ruleForm.resType" placeholder="请选择部门">
            <el-option v-for="item in roleType" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否显示">
          <el-radio-group v-model="ruleForm.perms">
            <el-radio label="0">
              是
            </el-radio>
            <el-radio label="2">
              否
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="部门" prop="orgCode">
          <el-select size="small" filterable v-model="ruleForm.orgCode" placeholder="请选择部门">
            <el-option
              v-for="item in orgCodeArr"
              :key="item.id"
              :label="item.name"
              :value="`${item.id}`"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button :loading="loading" type="primary" @click="saveEdit">
            确定
          </el-button>
          <el-button @click="ruleForm = {}">
            重置
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 新增弹出层 -->
      <el-dialog
        title="新增权限"
        :visible.sync="editFormVisible"
        width="40%"
        :close-on-click-modal="false"
        @click="closeDialog('edit')"
      >
        <el-form ref="editForm" label-width="120px" :model="editForm" :rules="rules">
          <el-form-item label="权限名称" prop="resName">
            <el-input v-model="editForm.resName" size="small" auto-complete="off" placeholder="权限名称" />
          </el-form-item>
          <el-form-item label="权限CODE" prop="resCode">
            <el-input v-model="editForm.resCode" size="small" auto-complete="off" placeholder="权限CODE" />
          </el-form-item>
          <el-form-item label="排序" prop="orders">
            <el-input v-model="editForm.orders" size="small" auto-complete="off" placeholder="请输入数字" type="number" />
          </el-form-item>
          <el-form-item label="权限类型" prop="resOperType">
            <el-select v-model="editForm.resOperType" size="small" filterable placeholder="请选择权限类型">
              <el-option v-for="item in qType" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="editForm.resOperType === 2" label="权限URL" prop="resUrl">
            <el-input v-model="editForm.resUrl" size="small" auto-complete="off" placeholder="权限URL" />
          </el-form-item>
          <el-form-item v-if="editForm.resOperType === 2 || editForm.resOperType === 1" label="icon" prop="icon">
            <el-input v-model="editForm.icon" size="small" auto-complete="off" placeholder="icon" />
          </el-form-item>
          <el-form-item label="上级">
            <el-cascader
              v-model="editForm.resPcode"
              :options="datas"
              :disabled="true"
              :props="{ emitPath: false, label: 'resName', value: 'resCode', checkStrictly: true }"
              clearable
              placeholder="不选默认为平台第一级"
            />
          </el-form-item>
          <el-form-item label="资源系统类型" prop="resSysType">
            <el-select v-model="editForm.resSysType" size="small" filterable placeholder="请选择资源系统类型">
              <el-option v-for="item in $store.getters.getDictionaryItem('RES_SYS_TYPE')" :key="item.dictId" :label="item.dictName" :value="item.dictId" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-radio-group v-model="ruleForm.perms">
              <el-radio label="0">
                是
              </el-radio>
              <el-radio label="2">
                否
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="资源类型">
            <el-select size="small" filterable v-model="editForm.resType" placeholder="请选择部门">
              <el-option
                v-for="item in roleType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>-->
          <!-- <el-form-item label="部门" prop="orgCode">
            <el-select size="small" filterable v-model="editForm.orgCode" placeholder="请选择部门">
              <el-option
                v-for="item in orgCodeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" plain class="widen-button" @click="editFormVisible = false">
            取消
          </el-button>
          <!-- <el-button size="small" @click="$resetForm('editForm')" type="success" plain>重置</el-button> -->
          <el-button
            size="small"
            type="primary"
            :loading="loading"
            class="title widen-button"
            @click="submitForm('editForm')"
          >
            保存
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// 转为树结构
import { translateDataToTree } from '../../utils/util'
import { qType, roleType } from '../../systemConfiguration/index'
import { getResourceBySysSelect, addAndEditPermission, deletePermission } from '../../api/systemManagement'
export default {
  data() {
    return {
      roleType,
      qType,
      roleTypeId: '00',
      roleTypeOptions: this.$store.getters.getDictionaryItem('SYS_TYPE'),
      editFormVisible: false,
      loading: false,
      expandAll: true,
      editForm: {},
      ruleForm: {},
      rules: {
        resName: [
          { required: true, message: '请输入权限名称', trigger: 'blur' }
        ],
        resUrl: [
          { required: true, message: '请输入权限URL', trigger: 'blur' }
        ],
        orders: [
          { required: true, message: '请输入序号', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '请输入icon', trigger: 'blur' }
        ],
        resCode: [
          { required: true, message: '请输入权限Code', trigger: 'blur' }
        ],
        resOperType: [
          { required: true, message: '请选择权限类型', trigger: 'change' }
        ],
        perms: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      datas: []
    }
  },
  mounted() {
    this.initList()
  },
  methods: {
    // 点击展开或者收缩按钮
    operationTree() {
      this.expandAll = !this.expandAll
      this.expandNodes(this.$refs.tree.store.root)
    },
    // 打开或者收缩树结构的方法
    expandNodes(node) {
      node.expanded = this.expandAll
      for (let i = 0; i < node.childNodes.length; i++) {
        node.childNodes[i].expanded = this.expandAll
        if (node.childNodes[i].childNodes.length > 0) {
          this.expandNodes(node.childNodes[i])
        }
      }
    },
    initList() {
      getResourceBySysSelect(this.roleTypeId, res => {
        this.datas = translateDataToTree(res.data, 'resPcode', 'resCode')
      })
    },
    // 新增子组织
    append(data) {
      this.editFormVisible = true
      this.editForm = {
        perms: '0'
      }
      this.editForm.resPcode = data.resCode
    },
    // 点击当前tree的节点
    handleNodeClick(node) {
      this.ruleForm = { ...node, perms: node.perms || '0' }
    },
    // 点击新增保存
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          this.loading = true
          this.editForm.type = 'add'
          this.editForm.resType = this.roleTypeId
          addAndEditPermission(this.editForm, res => {
            if (res.code === 200) {
              this.initList()
              this.$message.success('添加成功！')
            }
            this.editFormVisible = false
            this.loading = false
          }, () => {
            this.loading = false
          })
        }
      })
    },
    // 点击修改保存
    saveEdit() {
      delete this.ruleForm.createTime
      delete this.ruleForm.modifyTime
      this.ruleForm.type = 'edit'
      addAndEditPermission(this.ruleForm, res => {
        if (res.code === 200) {
          this.initList()
          this.$message.success('修改成功！')
        }
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    // 删除节点
    remove(node, data) {
      this.$confirm('此操作将删除此条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deletePermission({ cmpRole: this.roleTypeId, resCode: data.resCode, orgCode: data.orgCode }, res => {
            this.initList()
            this.$message.success('删除成功！')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style scoped>
.page-info-content {
  display: flex;
}

.Permission {
  flex: 1;
  margin: 30px 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.page-tree {
  max-height: 600px;
  overflow: auto;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.elSelect {
  width: 120px !important;
}

.titleBox {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f0f2f5;
  text-align: center;
  color: #334157;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
}

.el-form,
.el-tree {
  flex: 0 0 50%;
}

.el-cascader,
.el-select {
  width: 100%;
}
</style>
