// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
// 引入element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App'
// 引入路由
import router from './router'
// 引入状态管理
import store from './vuex/store'
// 引入icon
import './assets/icon/iconfont.css'
import './assets/css/custom.scss'
// 引入echarts
import * as echarts from 'echarts'
import { baseURL, IMGPATH, projectUrl } from './systemConfiguration/index'
// 引入码值
import codeValue from './utils/codeValue'
Vue.prototype.$echarts = echarts
try {
  Vue.prototype.$fileUrl = JSON.parse(localStorage.getItem('dictionaryEntry'))['documentLeadingEdge'][0].dictName
} catch (err) {
  console.log('HT369-V1.0-202212221' + err)
}
Vue.prototype.$getUserInfoParameter = function(parameter) {
  const userInfo = JSON.parse(localStorage.getItem('userdata'))
  return userInfo[parameter]
}
Vue.prototype.$localPicture = IMGPATH // 本地写代码图片地址
Vue.prototype.$baseUpload = `${baseURL}file/uploadfile`
Vue.prototype.$errImage = 'this.src="' + require('./assets/img/errImg.png') + '"' // 没有图片时候的显示
Vue.prototype.$defaultGraph = require('./assets/img/default_graph.png') // 没有图片时候的显示

Vue.prototype.$code = codeValue.getTextByCode
Vue.prototype.$codeNew = codeValue.getDictNameByData
Vue.prototype.$select = codeValue.getSelectByCode
Vue.prototype.$childrenSelect = codeValue.getChildrenByCode
Vue.prototype.$childrenCode = codeValue.getChildrenTextByCode
Vue.prototype.$arrayText = codeValue.getArrayTextByCode
Vue.prototype.$projectUrl = projectUrl
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.config.productionTip = false
// 使用element UI
Vue.use(ElementUI)
// 过滤器
import * as custom from './utils/util'

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})
// 如果是单独的按钮  可以进行这个来判断权限了
Vue.directive('isPermission', {
  inserted: function(el, binding, vnode) {
    if (binding.value) {
      const permission = JSON.parse(localStorage.getItem('userdata')).permission || []
      const flag = permission.some(item => binding.value === item.resCode)
      if (!flag)el.parentNode.removeChild(el)
    }
  },
  update: function(el, binding, vnode) {
    if (binding.value) {
      const permission = JSON.parse(localStorage.getItem('userdata')).permission || []
      const flag = permission.some(item => binding.value === item.resCode)
      el.style.display = !flag ? 'none' : 'block'
    }
  }
})
// 路由拦截器
router.beforeEach((to, from, next) => {
  if (to.matched.length !== 0) {
    if (to.meta.requireAuth) {
      // 判断该路由是否需要登录权限
      if ((localStorage.getItem('userInfo'))) {
        // 通过vuex state获取当前的user是否存在
        next()
      } else {
        next({
          path: '/login',
          query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
      }
    } else {
      next()
    }
  } else {
    next({
      path: '/homePage/index',
      query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
    })
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store, // 使用store vuex状态管理
  render: (h) => h(App)
  // data: {
  //   // 空的实例放到根组件下，所有的子组件都能调用
  //   Bus: new Vue()
  // }
}).$mount('#app')
