<template>
  <div class="materialManagement page-info-content">
    <div class="contentArea">
      <div class="left page-tree">
        <LeftTree :tree-data="formItem[0].options" @getTreeData="getTreeData" />
      </div>
      <div class="right">
        <!-- 搜索筛选 -->
        <FormSearch
          :getdata="getdata"
          :form-inline="formInline"
          :form-item-arr="searchFormItemArr"
          :reset="true"
          @resetFormInline="resetFormInline"
        >
          <template>
            <el-form-item>
              <el-select
                v-model="formInline.categoryId"
                size="small"
                placeholder="请选择"
                @change="getChildNode(1, formInline.categoryId)"
              >
                <el-option label="全部" value />
                <el-option
                  v-for="(item, index) in varietyData1"
                  :key="index"
                  :label="item.goodsName"
                  :value="item.code"
                />
              </el-select>
              <el-select
                v-if="varietyData2.length > 0"
                v-model="formInline.varietyId"
                size="small"
                placeholder="请选择"
                @change="getChildNode(2, formInline.varietyId)"
              >
                <el-option label="全部" value />
                <el-option
                  v-for="(item, index) in varietyData2"
                  :key="index"
                  :label="item.goodsName"
                  :value="item.code"
                />
              </el-select>
              <el-select
                v-if="varietyData3.length > 0"
                v-model="formInline.goodsId"
                size="small"
                placeholder="请选择"
                @change="$forceUpdate()"
              >
                <el-option label="全部" value />
                <el-option
                  v-for="(item, index) in varietyData3"
                  :key="index"
                  :label="item.goodsName"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </template>
        </FormSearch>
        <!--列表-->
        <div class="page-container-table">
          <div class="buttonCombination">
            <span>材质列表</span>
            <el-button size="small" type="primary" @click="editAndAddRow">
              添加
            </el-button>
          </div>
          <Table
            :item-data="itemData"
            :list-data="listData"
            :operation-button="operationButton"
            :loading="loading"
          />
          <!-- 分页组件 -->
          <!-- <Pagination
            :params="formInline"
            :total="total"
            :inArticle="listData.length"
            :getDataList="getdata"
          ></Pagination>-->
        </div>
      </div>
    </div>
    <!-- 编辑and新增弹出层 -->
    <Dialog
      ref="dialog"
      :edit-form-data="editFormData"
      :form-item="formItem"
      dialog-width="35%"
      @getFormData="getFormData"
    />
  </div>
</template>

<script>
import Dialog from '../../components/Dialog.vue'
import Table from '../../components/Table'
import LeftTree from './components/LeftTree'
import FormSearch from '../../components/FormSearch'
import { translateDataToTree } from '../../utils/util'
// import Pagination from '../../components/Pagination2'
import { addAndEditGoodsMaterials, getGoodsList, getChildGoodsList, getGoodsMaterialsList, goodsMaterialsDelete } from '../../api/goods'
export default {
  // 注册组件
  components: {
    // Pagination,
    Dialog,
    Table,
    LeftTree,
    FormSearch
  },
  data() {
    return {
      itemData: [
        {
          label: '材质',
          prop: 'materials'
        },
        {
          label: '关联品种',
          prop: 'goodsName'
        },
        {
          label: '状态',
          prop: 'state',
          child: [{ value: '1', label: '有效' }, { value: '0', label: '无效' }]
        }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow },
        { num: 0, val: 'state', bType: 'success', label: '启用', handleEvent: this.disableEnabledRow },
        { num: 1, val: 'state', bType: 'danger', label: '禁用', handleEvent: this.disableEnabledRow }
      ],
      loading: false,
      pinzhong: '',
      editFormData: {},
      formItem: [
        {
          type: 'cascader',
          value: 'allVarieties',
          label: '品种',
          props: { label: 'goodsName', value: 'code' },
          options: []
        },
        {
          type: 'input',
          value: 'materials',
          label: '材质型号',
          rules: [{ required: true, message: '请输入', trigger: ['blur', 'change'] },
            { min: 1, max: 100, message: '不超过100个字符', trigger: 'blur' }]
        }, {
          type: 'radio',
          value: 'state',
          label: '状态',
          child: [{ label: '无效', value: 0 }, { label: '有效', value: 1 }]
        }
      ],
      listData: [],
      varietyData1: [],
      varietyData2: [],
      varietyData3: [],
      formInline: {
        varietyId: '',
        categoryId: '',
        goodsId: ''
      },
      data: [],
      total: 0,
      searchFormItemArr: [
        { type: 'input', label: '材质名称', value: 'materials' }
      ]
    }
  },
  created() {
    // this.getdata()
    this.getChildNode()
    getGoodsList(res => {
      const arr = res.data.filter(item => item.state === 1)
      this.data = [...arr]
      this.formItem[0].options = translateDataToTree(arr, 'parentCode', 'code')
    })
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    resetFormInline() {
      this.formInline = {
        varietyId: '',
        categoryId: '',
        goodsId: ''
      }
    },
    // 修改和编辑
    editAndAddRow(row) {
      this.$refs.dialog.editFormVisible = true
      if (row.constructor === Object) {
        this.editFormData = { ...row }
      } else {
        this.editFormData = {}
      }
      this.editFormData.allVarieties = []
      if (row.constructor !== Object && this.formInline.varietyId && this.formInline.categoryId && this.formInline.goodsId) {
        this.editFormData.allVarieties.push(this.formInline.categoryId)
        this.editFormData.allVarieties.push(this.formInline.varietyId)
        this.editFormData.allVarieties.push(this.formInline.goodsId)
        return
      }
      // 呈现给级联上
      if (row && row.categoryId) this.editFormData.allVarieties.push(row.categoryId)
      if (row && row.varietyId) this.editFormData.allVarieties.push(row.varietyId)
      if (row && row.goodsId) this.editFormData.allVarieties.push(row.goodsId)
    },
    // 点击新增或者修改的确认按钮
    getFormData(value) {
      if (value.allVarieties.length !== 3) {
        this.$message.error('要选到产品的第三级才可以！')
        return
      }
      // 将上面的三个参数赋值给相应的
      const item = { ...value }
      item.categoryId = item.allVarieties[0]
      item.varietyId = item.allVarieties[1]
      item.goodsId = item.allVarieties[2]
      this.data.forEach(val => {
        if (val.code === item.goodsId) {
          item.goodsName = val.goodsName
        }
      })
      addAndEditGoodsMaterials(item, () => {
        this.$refs.dialog.editFormVisible = false
        this.$message.success('成功！')
        this.getdata()
      })
    },
    // 获取数据
    getdata(type) {
      getGoodsMaterialsList(this.formInline, res => {
        this.listData = [...res.data]
      })
    },
    // 获取树结构的相应东西
    getTreeData(value) {
      switch (value.level) {
        case 1:
          this.formInline.categoryId = value.code
          this.formInline.varietyId = ''
          this.formInline.goodsId = ''
          break
        case 2:
          this.formInline.categoryId = value.row.parentCode
          this.formInline.varietyId = value.code
          this.formInline.goodsId = ''
          break
        case 3:
          this.formInline.goodsId = value.code
          this.formInline.varietyId = value.row.parentCode
          this.$forceUpdate()
          break
      }
      if (value.level !== 3) this.getChildNode(value.level, value.code)
      this.getdata()
    },
    // 禁用或者启用
    disableEnabledRow(row) {
      this.$confirm('此操作将修改材质状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        goodsMaterialsDelete({ id: row.id, state: row.state === 0 ? 1 : 0 }, () => {
          this.$message.success('成功')
          this.getdata()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 根据父节点获取下面的子节点
    getChildNode(type, pId) {
      getChildGoodsList(pId || '', res => {
        const arr = res.data.filter(item => item.state === 1)
        switch (type) {
          case 1:
            this.varietyData2 = [...arr]
            this.formInline.goodsId = ''
            this.formInline.varietyId = ''
            this.varietyData3 = []
            break
          case 2:
            this.formInline.goodsId = ''
            this.varietyData3 = [...arr]
            break
          default:
            this.varietyData1 = [...arr]
            break
        }
      })
    }
  }
}
</script>

<style scoped>
.contentArea {
  flex: 1;
  display: flex;
  overflow-x: hidden;
}
.contentArea .left {
  flex: 0 0 200px;
  padding-right: 12px;
  overflow-x: auto;
  margin-right: 20px;
}
.contentArea .right {
  width: calc(100% - 212px);
}
.form-search {
  box-shadow: none;
  padding: 0;
  margin-bottom: 0;
}
.userRole {
  width: 100%;
}
.buttonCombination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  font-size: 14px;
  border: 1px solid #eee;
  border-bottom: 0;
  padding: 0 16px;
}
</style>

