// amountManage.js
import { postRequest, getRequest } from './index'
import { scfURL, baseURL } from '@/systemConfiguration/index'
// 核心企业额度查询
export const getAmountCoreList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/quota/selectAll',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 核心企业额度-查询额度详情
export const getAmountCoreDetail = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/quota/getQuotaInfo',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 产品模块
// 动态加载【企业角色】包含“核心企业”的企业会员
export const getAllCoreEnterprie = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/selectAllCmpIncludeStop',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 查询资金方
export const selectCmpName = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    'funder/listFunderByCoreCmpUnicode',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品管理-列表
export const productManageList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/prodSelect',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// /plat/approveabc/compactService/listByCompactNameLike2Platform
// 产品配置合同模板
export const getProCompact = (
  data,
  successCallback,
  failureCallback
) => {
  getRequest(`/compactService/listByCompactNameLike2Platform`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 产品管理-编辑-提交
export const UpdateSubmitProduct = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/prodSubmitUpdate',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品管理-编辑-保存
export const UpdateProduct = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/prodUpdate',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 获取跟产品相关的核心企业
export const getCmpListByCmpRoleList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    baseURL + '/cmp/baseinfo/selectCmpListByCmpRole',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品新增查询已激活的资金方名称 /plat/v2/financeFundcmp/selectCmpName
export const getFinancialSideList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    baseURL + '/v2/financeFundcmp/selectCmpName',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品管理-详情
export const productDetail = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/prodSelectOne',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品管理-新增-保存
export const AddProduct = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/prodAdd',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品管理-新增-提交
export const AddSubmitProduct = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/prodSubmit',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品审核-保存
export const saveApprove = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/examine',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品管理-启用
export const startProduct = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/prodStart',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品管理-停用
export const stopProduct = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/prodStop',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品管理-编辑前查询详情
export const UpdateFrontProduct = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/selectAndUpdate',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 请求工作流审核历史
export const getAuditHistory = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/task/tapinfo',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 请求审核意见的回退原因
export const getAuditReason = (
  data,
  successCallback,
  failureCallback
) => {
  getRequest(scfURL + `/task/reason`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 请求审核结果按钮 (通过，拒绝，回退)
export const getResourceByTask = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/task/getResourceByTask',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 查询产品历史数据信息
export const prodBaseinfoHis = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/prodBaseinfoHis',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 通过资金方id和核心企业id查询产品名
export const selectProductName = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v1/prod/selectProName',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 产品菜单2级菜单
// 授信机构管理列表
export const selectGuarGreditFundList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/guarCreditFund/selectList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 授信机构管理-详情
export const guarGreditFundDetail = (id, successCallback, failureCallback) => {
  getRequest(scfURL + `/v2/guarCreditFund/selectByPrimaryKey/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 授信机构管理-编辑
export const updateOrderInfo = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/guarCreditFund/updateGuarCreditFund',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 授信机构管理-启用停用
export const guarGreditFundUpdateState = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/guarCreditFund/updateState',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 授信机构管理-新增
export const addGuarCreditFund = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/guarCreditFund/addGuarCreditFund',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 产品模板  担保产品管理二级菜单3
export const selectGuarProdInfoList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/guarProdInfo/selectList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 额度管理
// 供应商额度
export const getSupplierList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/quotaCmp/selectAll',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 供应商额度编辑
export const editSupplierAmount = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/quotaCmp/quotaCmpUpdate',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 额度管理
// 核心企业额度
// 核心企业额度录入--新增
export const quotaInsert = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/quota/quotaInsert',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 核心企业额度详情页- 添加产品专项额度
export const quotaProdInsert = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/quotaProd/quotaProdInsert',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 核心企业额度录入--修改
export const quotaUpdate = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/quota/quotaUpdate',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 核心企业额度详情页- 调整产品专项额度
export const quotaProdUpdate = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/quotaProd/quotaProdUpdate',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 额度管理 二级菜单集团额度
// 集团额度查询
export const selectGroupList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/financeQuotaGroup/selectList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 新增里集团名称和资金方名称下拉列表
export const selectDistinctList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/v2/financeFundcmp/selectDistinctList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 新增集团额度
export const insertSelective = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/financeQuotaGroup/insertSelective',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 集团额度详情查询接口
export const selectGroupByPrimaryKey = (id, successCallback, failureCallback) => {
  getRequest(`${scfURL}/v2/financeQuotaGroup/selectByPrimaryKey/${id}`, {}, res => successCallback(res), error => {
    if (failureCallback) failureCallback(error)
  }
  )
}
export const updateGroupSelective = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/financeQuotaGroup/updateSelective',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 企业应收付分页查询
export const payalbeBillInfoQueryByPage = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/payalbeBillInfo/queryByPage',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 企业应收付详情/编辑查询接口
export const payalbeBillInfoDetail = (
  id,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + '/payalbeBillInfo/detail/' + id,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 一键下载
export const payalbeBillInfoToManageDownload = (
  id,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + '/v1/payalbeBillInfoToManage/download/' + id,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 查询反向保理融资信息列表(带分页)
export const getReverseFactoringList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/finance/selectFinanceList',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 反向融资审核-保存
export const saveFinanceApprove = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/finance/platformExamine2',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 查询融资申请信息详情
export const getSelectFinanceDetails = (
  id,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + '/v2/finance/selectFinanceDetails/' + id,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 标记放款
export const signLoan = (
  id,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + '/v2/finance/signLoan/' + id,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 查询是否能平台端-审核融资按钮--true展示 false置灰
export const checkExamineFinanceButton = (
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + '/v2/financeTime/checkExamineFinanceButton',
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 根据融资申请id查询融资申请协议
export const getProtocolByFinaceId = (
  financeId,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + `/v2/finance/protocol/list/${financeId}`,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 供应商-信链申请记录列表
export const selectCmpApplaylist = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + '/v2/echainApplay/selectPlatApplaylist',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 申请记录的详情
export const selectCmpApplayInfo = (
  id,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + `/v2/echainApplay/selectCmpApplayInfo/${id}`,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 付款明细表信息
export const inalPayDetail = (
  id,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + `/v2/transferQuery/inalPayDetail/${id}`,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 12融资管理 查询
export const selectFinanceList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + `/v2/echainFinance/selectFinanceList?d=${new Date().getTime()}`,
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 11融资管理 协议详情
export const selectPdfList = (
  id,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + `/v2/echainFinance/selectPdfList/${id}`,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 11融资管理 查询融资进度
export const selectFinanceSpeed = (
  id,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + `/v2/echainFinance/selectFinanceSpeed/${id}`,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 11.1融资管理 查看详情
export const selectFinanceInfo = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + `/v2/echainFinance/selectFinanceInfo`,
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}

// 信链路经查询
export const selectAllForEnte = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + `/v2/transferQuery/selectAllForPlat`,
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 信链流转登记-查询
export const getEchainTransferList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + `/v2/register/selectAll`,
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 信链流转登记-详情列表查询
export const selectInfoList = (
  id,
  successCallback,
  failureCallback
) => {
  getRequest(
    scfURL + `/v2/register/selectInfoList/${id}`,
    {},
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 信链流转登记-登记列表查询
export const selectRegisterList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    scfURL + `/v2/register/selectRegisterList`,
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
