/**
* 左边菜单
*/
<template>
  <div>
    <el-menu
      :collapse="collapsed"
      collapse-transition
      router
      :default-active="defaultActive"
      unique-opened
      class="el-menu-vertical-demo"
      :background-color="$store.state.customColors"
      text-color="#2E2E2E"
      active-text-color="#0089FF"
    >
      <!-- <div class="logobox" :style="'border-color:' + $store.state.customColors">
        <router-link :to="{ path: '/homePage/index' }">
          <img class="logoimg" :src="$localPicture+'logo/logo_small.png'" alt>
          <p v-show="!collapsed" class="elp-1">
            {{ systemMenuObj.resName }}
          </p>
        </router-link>
        <el-tooltip class="item" effect="dark" :content="isCollapsing ? '展开' : '收起'" placement="right">
          <i :class="isCollapsing ? 'icon menu_stow' : 'icon menu-open'" @click="changeCollapsed" />
        </el-tooltip>
      </div> -->
      <div v-for="menu in allmenu" :key="menu.resCode">
        <el-submenu
          v-if="menu.resOperType === '1'"
          :index="menu.resCode"
          :class="collapsed ? 'menu-hide' : 'menu-display'"
        >
          <template slot="title">
            <i :class="menu.icon || 'el-icon-s-order'" />
            <span v-show="!collapsed">{{ menu.resName }}</span>
          </template>
          <el-menu-item-group>
            <div v-for="chmenu in menu.children" :key="chmenu.resCode">
              <el-menu-item v-if="chmenu.resOperType === '2'" :index="chmenu.resUrl">
                <div>
                  <!-- <i :class="chmenu.icon||'el-icon-s-order'"></i> -->
                  <span>{{ chmenu.resName }}</span>
                </div>
              </el-menu-item>
            </div>
          </el-menu-item-group>
        </el-submenu>
        <!-- 直接是没有子孩子的 -->
        <el-menu-item v-else-if="menu.resOperType === '2'" :index="menu.resUrl">
          <i :class="menu.icon || 'el-icon-s-order'" />
          <span v-show="!collapsed">{{ menu.resName }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: 'Leftnav',
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      collapsed: false,
      defaultActive: '',
      allmenu: [],
      systemMenuObj: {},
      isCollapsing: false
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        this.defaultActive = newVal.meta.parentPath || sessionStorage.getItem('defaultActivePath')
        sessionStorage.setItem('defaultActivePath', newVal.meta.parentPath || sessionStorage.getItem('defaultActivePath'))
      },
      immediate: true,
      deep: true
    },
    isShow: {
      handler(newVal) {
        this.collapsed = newVal
      },
      immediate: true,
      deep: true
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    this.systemMenuObj = this.$store.systemMenuObj || { ...JSON.parse(localStorage.getItem('systemMenuObj')) }
    const systemMenuArr = this.systemMenuObj.children || []
    this.allmenu = [{
      icon: 'el-icon-s-home',
      modifyUser: 'admin',
      orgCode: '0000',
      resCode: '/homePage/index',
      resName: '首页',
      resOperType: '2',
      resPcode: 'cmp_system',
      resType: '00',
      resUrl: '/homePage/index'
    }, ...systemMenuArr]
    // 从缓存中取出所拥有的的权限  进行判断他有那些菜单
    // let permission = JSON.parse(localStorage.getItem('userdata')).permission||[];
    // let arr = [];
    // // 取唯一标识
    // permission.forEach(item => {
    //   arr.push(item.resCode)
    // })
    // getAllPermission(res => {
    //   let data = [...res.data]
    //   if (data.length > 0 && data[0].children) this.allmenu = [...data[0].children]
    // }, () => { }, '00')
  },
  methods: {
    // changeCollapsed() {
    //   this.isCollapsing = !this.isCollapsing
    //   if (this.isCollapsing) {
    //     this.collapsed = true
    //   }
    //   this.$emit('changeWidth', { collapsed: this.collapsed, isCollapsing: this.isCollapsing })
    // },
    // menuOpen() {
    //   if (this.isCollapsing) {
    //     this.collapsed = false
    //     this.$emit('changeWidth', { collapsed: this.collapsed, isCollapsing: this.isCollapsing, showPlaceholder: true })
    //   }
    // },
    // menuCollapse() {
    //   if (this.isCollapsing) {
    //     this.collapsed = true
    //     this.$emit('changeWidth', { collapsed: this.collapsed, isCollapsing: this.isCollapsing, showPlaceholder: false })
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.el-menu--collapse {
  width: 85px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: 400px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}

.el-menu-item [class^=el-icon-] {
  // margin-left: 12px;
}

.el-submenu [class^=el-icon-] {
  // margin-left: 12px;
}

/deep/.el-menu-item-group__title {
  padding: 0px !important;
}

.el-menu-bg {
  background-color: #1f2d3d !important;
}

.el-menu {
  border: none;
}

.logobox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  font-size: 18px;
  text-align: center;
  padding: 0 22px;
  height: 104px;
  font-weight: bold;
  border-bottom: 1px solid #EFEFEF;

  >a {
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
  }

  .logoimg {
    margin-right: 18px;
    height: 56px;
    width: 56px;
  }

  .icon {
    width: 23px;
    height: 23px;
    cursor: pointer;
    display: inline-block;
    transition: 0.4s;
  }

  .menu-open {
    background: url('../assets/img/menu_open.svg') no-repeat top center;

    &:hover {
      background: url('../assets/img/menu_open_a.svg') no-repeat top center;
    }
  }

  .menu_stow {
    background: url('../assets/img/menu_open.svg') no-repeat top center;
    transform: rotateY(180deg);

    &:hover {
      background: url('../assets/img/menu_open_a.svg') no-repeat top center;
    }
  }
}

.menu-hide {
  /deep/ .el-submenu__icon-arrow {
    display: none;
  }
}

.menu-display {
  /deep/ .el-submenu__icon-arrow {
    display: block;
  }
}

.el-submenu .el-menu-item {
  padding-left: 50px !important;
}
</style>
