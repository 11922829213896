import { getRequest, postRequest, putRequest } from './index'
import { scfURL } from '@/systemConfiguration/index'
// 企业信息
// 企业基础信息添加
export const cmpBaseinfoAddAndEdit = (data, successCallback, failureCallback) => {
  postRequest(data.cmpCode ? '/cmp/baseinfo/update' : '/cmp/baseinfo/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业基础信息分页
export const cmpBaseinfoPage = (data, successCallback, failureCallback) => {
  postRequest('/cmp/baseinfo/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业基础信息下拉带模糊搜索
export const getCmpBaseinfoList = (cmpName, successCallback, failureCallback) => {
  getRequest('/cmp/baseinfo/list?cmpName=' + cmpName, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业基础信息下拉列表--已通过审核
export const getCmpBaseinfoPassList = (cmpName, successCallback, failureCallback) => {
  getRequest('/cmp/baseinfo/pass/list?cmpName=' + cmpName, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业基础信息详情
export const cmpBaseinfoDetail = (cmpCode, successCallback, failureCallback) => {
  postRequest('/cmp/baseinfo/detail', { cmpCode }, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业基础信息审核
export const cmpBaseinfoAudit = (data, successCallback, failureCallback) => {
  postRequest('/cmp/baseinfo/audit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业基础信息禁用启用
export const cmpBaseinfoEnableOrDisable = (data, successCallback, failureCallback) => {
  postRequest('/cmp/baseinfo/enableOrDisable', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业信息审核流程带分页
export const cmpApprovePage = (data, successCallback, failureCallback) => {
  postRequest('/cmp/approve/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业开户信息分页
export const cmpAccountPage = (data, successCallback, failureCallback) => {
  postRequest('/cmp/account/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业开票信息分页
export const cmpInvoicePage = (data, successCallback, failureCallback) => {
  postRequest('/cmp/invoice/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业开票信息新增与修改
export const cmpInvoiceUpdateAndAdd = (data, successCallback, failureCallback) => {
  postRequest(data.id ? '/cmp/invoice/update' : '/cmp/invoice/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业开票信息禁用与启用
export const cmpInvoiceEnableOrDisablt = (data, successCallback, failureCallback) => {
  postRequest('/cmp/invoice/enableOrDisablt', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 设置默认地址
export const cmpInvoiceUpdateDefaultAddress = (data, successCallback, failureCallback) => {
  postRequest('/cmp/invoice/updateDefaultAddress', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板
// 合同模板分页
export const compactServiceListPage = (data, successCallback, failureCallback) => {
  postRequest('/compactService/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板新增跟修改
export const compactServiceSaveAndUpdata = (data, successCallback, failureCallback) => {
  postRequest('/compactService/saveAndUpdata', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板删除
export const compactServiceDelete = (data, successCallback, failureCallback) => {
  postRequest('/compactService/delete', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 会员审核的审核条件有几个  是从后台获取
export const getResourceByTask = (data, successCallback, failureCallback) => {
  postRequest('/task/getResourceByTask', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合作企业列表
export const agentSupplierCmpAgentSupplierCmp = (data, successCallback, failureCallback) => {
  postRequest('/agentSupplierCmp/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合作企业关联代采商列表
export const agentSupplierCmpCust = (data, successCallback, failureCallback) => {
  postRequest('/agentSupplierCmp/cust/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 新增跟编辑合作企业列表
export const addAndEditAgentSupplierCmp = (data, successCallback, failureCallback) => {
  postRequest(data.id ? 'agentSupplierCmp/edit' : 'agentSupplierCmp/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业会员管理-关联企业
export const relationCmpList = (data, successCallback, failureCallback) => {
  postRequest('/fundmember/relacontrol', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加关联企业的模糊查询
export const selectNoCmpRelation = (data, successCallback, failureCallback) => {
  postRequest('/v2/financeCmpRelation/selectNoCmpRelation', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加关联企业
export const addFinanceCmpRelation = (data, successCallback, failureCallback) => {
  postRequest('/v2/financeCmpRelation/addFinanceCmpRelation', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 删除企业关联关系
export const deleteCmpRelation = (id, successCallback, failureCallback) => {
  getRequest(`/v2/financeCmpRelation/deleteCmpRelation/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商圈配置列表
export const getBussinessManageList = (data, successCallback, failureCallback) => {
  postRequest('/v2/financeFundcmp/selectList', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
export const getBankAndCoreList = (data, successCallback, failureCallback) => {
  postRequest('/v2/financePushBank/selectBankAndCoreList', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询资金方企业名称列表
export const selectFundcmp = (successCallback, failureCallback) => {
  getRequest(`/funder/list`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询核心企业
export const getCmpListByCmpRoleList = (
  data,
  successCallback,
  failureCallback
) => {
  postRequest(
    '/cmp/baseinfo/selectCmpListByCmpRole',
    data,
    res => successCallback(res),
    error => {
      if (failureCallback) failureCallback(error)
    }
  )
}
// 商圈配置列表-新增
export const insertBusinessList = (data, successCallback, failureCallback) => {
  postRequest('/v2/financeFundcmp/insert', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商圈配置详情
export const selectBusinessInfo = (id, successCallback, failureCallback) => {
  getRequest(`/v2/financeFundcmp/selectInfo/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商圈配置作废
export const stopBusinessInfo = (id, successCallback, failureCallback) => {
  getRequest(`/v2/financeFundcmp/stop/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 银行准入管理-列表
export const bankAccessList = (data, successCallback, failureCallback) => {
  postRequest(scfURL + '/v2/financePushBank/selectPushBankList', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 银行准入管理-审核
export const bankAccessListExamine = (data, successCallback, failureCallback) => {
  postRequest(scfURL + '/v2/financePushBank/examine', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 银行准入-推送银行-查所属集团和银行产品编码 v2/financePushBank/selectGroupList
export const selectGroupList = (data, successCallback, failureCallback) => {
  postRequest('/v2/financeFundcmp/selectGroupList', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 银行准入管理-详情
export const bankAccessDetail = (id, successCallback, failureCallback) => {
  getRequest(scfURL + `/v2/financePushBank/selectInfo/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询银行产品编码list
export const selectBankProList = (data, successCallback, failureCallback) => {
  postRequest('/v2/financeFundcmp/selectBankProList', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询可推送供应商列表
export const selectPushCmpList = (data, successCallback, failureCallback) => {
  postRequest('/v2/financeCmpRelation/selectPushCmpList', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加推送供应商
export const addPushCmp = (data, successCallback, failureCallback) => {
  postRequest(scfURL + '/v2/financePushBank/addPushCmp', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 融资基础信息审核
export const financeAudit = (data, successCallback, failureCallback) => {
  putRequest(scfURL + '/finance/audit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
