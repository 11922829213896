/** * 系统管理 角色管理 */
<template>
  <div>
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :reset="false"
      :form-item-arr="searchFormItemArr"
    />
    <!--列表-->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="handleEdit">
          添加
        </el-button>
      </div>
      <Table
        :operation-width="240"
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
    </div>
    <!-- 编辑界面 -->
    <el-dialog
      :title="title"
      :visible.sync="editFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @click="closeDialog('edit')"
    >
      <el-form
        ref="editForm"
        label-width="120px"
        :model="editForm"
        :rules="rules"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input
            v-model="editForm.roleName"
            size="small"
            auto-complete="off"
            placeholder="请输入角色名称"
          />
        </el-form-item>
        <!-- <el-form-item label="角色类型" prop="roleType">
          <el-select v-model="editForm.roleType" placeholder="请选择角色类型">
            <el-option v-for="item in roleType" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="授权系统" prop="cmpRole">
          <el-select v-model="editForm.cmpRole" placeholder="请选择授权系统">
            <el-option
              v-for="item in $store.getters.getDictionaryItem('SYS_TYPE')"
              :key="item.dictId"
              :label="item.dictName"
              :value="item.dictId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="editForm.remark"
            size="small"
            auto-complete="off"
            placeholder="请输入备注"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          plain
          class="widen-button"
          @click="closeDialog('edit')"
        >
          取消
        </el-button>
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          class="title widen-button"
          @click="submitForm('editForm')"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
    <!-- 菜单权限 -->
    <el-dialog
      title="菜单权限"
      :visible.sync="menuAccessshow"
      width="30%"
      :close-on-click-modal="false"
      @click="closeDialog('perm')"
    >
      <el-tree
        ref="tree"
        default-expand-all
        :data="roleRight"
        :props="roleRightProps"
        node-key="resCode"
        :show-checkbox="true"
        :check-strictly="true"
        @check="checkBox"
      />
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          plain
          class="widen-button"
          @click="closeDialog('perm')"
        >
          取消
        </el-button>
        <!-- <el-button size="small" @click="$resetForm('editForm')" type="success" plain>重置</el-button> -->
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          class="title widen-button"
          @click="menuPermSave"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { roleType, rolePlatform } from '../../systemConfiguration/index'
import {
  getResourceBySysSelect,
  saveRolePermission,
  getAllPermission,
  getRoleOfResource,
  getAllRoles,
  addRole,
  updateRole,
  deleteRole
} from '../../api/systemManagement.js'
import Table from '@/components/Table'
import FormSearch from '../../components/FormSearch'
export default {
  components: { Table, FormSearch },
  data() {
    return {
      // 请求数据参数
      formInline: {
        roleName: ''
      },
      assignmenArr: [],
      searchFormItemArr: [
        { type: 'input', label: '角色名称', value: 'roleName' }
      ],
      roleType,
      rolePlatform,
      loading: false, // 是显示加载
      editFormVisible: false, // 控制编辑页面显示与隐藏
      menuAccessshow: false, // 控制数据权限显示与隐藏
      title: '添加',
      editForm: {},
      // rules 表单验证
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ],
        cmpRole: [
          { required: true, message: '请选择授权系统', trigger: 'change' }
        ]
      },
      listData: [], // 用户数据
      roleRight: [],
      roleRightProps: {
        children: 'children',
        label: 'resName'
      },
      // 参数role
      saveroleId: '',
      checkChildArr: [],
      itemData: [
        { label: '角色名称', prop: 'roleName', sortable: true },
        // { label: '角色类型', prop: 'roleType', sortable: true },
        {
          label: '授权系统',
          prop: 'usePlatform',
          child: this.$store.getters.getDictionaryItem('SYS_TYPE')
        },
        { label: '备注', prop: 'remark' }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.handleEdit },
        { bType: 'danger', label: '删除', handleEvent: this.deleteUser },
        { bType: 'success', label: '授权', handleEvent: this.menuAccess }
      ]
    }
  },
  watch: {
    roleRight: {
      handler(newVal) {
        newVal.forEach(item => {
          this.lookUpElement(item)
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 点击复选框的操作
    checkBox(node) {
      // 把下面的都选上
      // 先去要修改的元素上找一下   然后在进行赋值
      this.checkRole(this.roleRight, node, !node.flag)
    },
    checkRole(arr, node, type) {
      arr.forEach(item => {
        // 如果找到；了  就对他的子元素进行勾选
        if (item.resCode === node.resCode) {
          this.$set(item, 'flag', type)
          if (item.children && item.children.length > 0) this.selectChildElement(item.children, type)
          return
        }
        if (item.children && item.children.length > 0) {
          this.checkRole(item.children, node, type)
        }
      })
    },
    // 向上选取父元素啊
    lookUpElement(obj) {
      if (obj.children && obj.children.length > 0) {
        let flag = false
        obj.children.forEach(item => {
          if (item.flag) flag = true
          if (item.children && item.children.length > 0) {
            this.lookUpElement(item)
          }
        })
        this.$set(obj, 'flag', flag)
        if (this.$refs['tree']) this.$refs['tree'].setChecked(obj, flag)
      }
    },
    // 选上子元素啊
    selectChildElement(arr = [], type) {
      if (arr.length === 0) return
      arr.forEach(item => {
        this.$set(item, 'flag', type)
        this.$refs['tree'].setChecked(item, type)
        if (item.children && item.children.length > 0) {
          this.selectChildElement(item.children, type)
        }
      })
    },
    // 获取所有的角色
    getAllRoleList(roleType) {
      getAllPermission(
        res => {
          this.menuAccessshow = true
          this.roleRight = [...res.data]
        },
        () => {},
        roleType
      )
    },
    // 获取角色列表
    getdata() {
      getAllRoles(this.formInline.roleName || '', res => {
        this.listData = res.data
      })
    },
    // 显示编辑界面
    handleEdit(row) {
      this.editFormVisible = true
      if (row.constructor === Object) {
        this.title = '修改'
        this.editForm = { ...row, cmpRole: row.usePlatform }
        delete this.editForm.createTime
      } else {
        this.title = '添加'
        this.editForm = {}
      }
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          this.loading = true
          // 修改
          if (this.editForm.roleCode) {
            updateRole(
              { ...this.editForm },
              res => {
                this.loading = false
                this.editFormVisible = false
                this.getdata(true)
                this.$message.success('编辑成功！')
              },
              () => {
                this.loading = false
              }
            )
            return
          }
          // 新增
          addRole(
            { ...this.editForm },
            res => {
              this.loading = false
              this.editFormVisible = false
              this.getdata(true)
              this.$message.success('新增成功！')
            },
            () => {
              this.loading = false
            }
          )
        }
      })
    },
    // 删除角色
    deleteUser(row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteRole(row.roleCode, () => {
            this.$message.success('删除成功！')
            this.getdata(true)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 数据权限
    menuAccess(row) {
      this.roleCode = row.roleCode
      this.checkChildArr = []
      // 没有异步转为同步   把要渲染的接口放在这个接口里面
      // 获取当前平台的所有权限
      getResourceBySysSelect(row.usePlatform, res => {
        this.menuAccessshow = true
        this.roleRight = [...res.data]
        // 获取该角色所拥有的权限
        getRoleOfResource(
          row.roleCode,
          r => {
            this.recursiveSearch(this.roleRight, r.data)
            this.$refs.tree.setCheckedKeys(r.data || [])
          },
          () => {
            this.$refs.tree.setCheckedKeys([])
          }
        )
      })
    },
    recursiveSearch(arr, checkedArr = []) {
      if (checkedArr.length === 0) {
        return
      }
      arr.forEach(item => {
        if (item.children && item.children.length > 0) {
          this.recursiveSearch(item.children, checkedArr)
        }
        if (checkedArr.includes(item.resCode)) {
          item.flag = true
          return
        }
        item.flag = false
      })
    },
    // 菜单权限-保存
    menuPermSave() {
      saveRolePermission(
        {
          params: {
            resourceList:
              [
                ...this.$refs.tree.getCheckedKeys(),
                ...this.$refs.tree.getHalfCheckedKeys(true)
              ] || [],
            roleCode: this.roleCode
          }
        },
        () => {
          this.menuAccessshow = false
          this.$message.success('保存成功！')
        }
      )
    },
    // 关闭编辑、增加弹出框
    closeDialog(dialog) {
      if (dialog === 'edit') {
        this.editFormVisible = false
      } else if (dialog === 'perm') {
        this.menuAccessshow = false
      }
    }
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}
</style>
