import { render, staticRenderFns } from "./leftnav.vue?vue&type=template&id=439d1938&scoped=true&"
import script from "./leftnav.vue?vue&type=script&lang=js&"
export * from "./leftnav.vue?vue&type=script&lang=js&"
import style0 from "./leftnav.vue?vue&type=style&index=0&id=439d1938&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "439d1938",
  null
  
)

export default component.exports