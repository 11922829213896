const generationMiningRoute = [
  {
    path: '/cooperativeOfficeOA/paymentApprover',
    name: 'paymentApprover',
    component: () => import('@/views/generationMining/paymentApprover.vue'),
    meta: {
      parentPath: '/cooperativeOfficeOA/paymentApprover',
      requireAuth: true,
      keepAlive: true,
      title: '付款审批'
    }
  },
  {
    path: '/cooperativeOfficeOA/sealApprover',
    name: 'sealApprover',
    component: () => import('@/views/generationMining/paymentApprover.vue'),
    meta: {
      parentPath: '/cooperativeOfficeOA/sealApprover',
      requireAuth: true,
      keepAlive: true,
      title: '用印审批'
    }
  },
  {
    path: '/cooperativeOfficeOA/ladingApproval',
    name: 'ladingApproval',
    component: () => import('@/views/generationMining/paymentApprover.vue'),
    meta: {
      parentPath: '/cooperativeOfficeOA/ladingApproval',
      requireAuth: true,
      keepAlive: true,
      title: '提单审批'
    }
  },
  {
    path: '/crmManagement/cooperativeEnterprise',
    name: 'cooperativeEnterprise',
    meta: {
      title: '合作企业管理',
      parentPath: '/crmManagement/cooperativeEnterprise',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/cooperativeEnterprise.vue')
  },
  {
    path: '/crmManagement/cooperativeEnterpriseDeails',
    name: 'cooperativeEnterpriseDeails',
    meta: {
      title: '合作企业详情',
      parentPath: '/crmManagement/cooperativeEnterprise',
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/children/cooperativeEnterpriseDeails.vue')
  }, {
    path: '/settlementManage/index',
    name: 'settlementManage',
    meta: {
      title: '结算管理',
      parentPath: '/settlementManage/index',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/settlementManage.vue')
  },
  {
    path: '/settlementManage/settlementDetails',
    name: 'settlementDetails',
    meta: {
      title: '结算详情',
      parentPath: '/settlementManage/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/children/settlementDetails.vue')
  },
  {
    path: '/crmManagement/creditManagement',
    name: 'creditManagement',
    meta: {
      title: '授信管理',
      parentPath: '/crmManagement/creditManagement',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/creditManagement.vue')
  }, {
    path: '/crmManagement/creditDetails',
    name: 'creditDetails',
    meta: {
      title: '审核',
      parentPath: '/crmManagement/creditManagement',
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/children/creditDetails.vue')
  },
  {
    path: '/procurementContract/index',
    name: 'procurementContract',
    meta: {
      title: '采购合同管理',
      parentPath: '/procurementContract/index',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/procurementContract.vue')
  },
  {
    path: '/procurementContract/procurementContractDetails',
    name: 'procurementContractDetails',
    meta: {
      title: '采购合同详情',
      parentPath: '/procurementContract/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/children/procurementContractDetails.vue')
  },
  {
    path: '/salesContractManagement/index',
    name: 'salesContractManagement',
    meta: {
      title: '销售合同管理',
      parentPath: '/salesContractManagement/index',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/salesContractManagement.vue')
  },
  {
    path: '/salesContractManagement/addSalesContract',
    name: 'addSalesContract',
    meta: {
      title: '录入结算价格',
      parentPath: '/salesContractManagement/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/children/salesContractDetails.vue')
  },
  {
    path: '/salesContractManagement/salesContractDetails',
    name: 'salesContractDetails',
    meta: {
      title: '销售合同详情',
      parentPath: '/salesContractManagement/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/children/salesContractDetails.vue')
  },
  {
    path: '/pickUpGoods/index',
    name: 'pickUpGoods',
    meta: {
      title: '提货管理',
      parentPath: '/pickUpGoods/index',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/pickUpGoods.vue')
  },
  {
    path: '/pickUpGoods/pickUpGoodsDetails',
    name: 'pickUpGoodsDetails',
    meta: {
      title: '提货详情',
      parentPath: '/pickUpGoods/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/children/pickUpGoodsDetails.vue')
  },
  {
    path: '/cooperativeOfficeOA/approvalDetails',
    name: 'approvalDetails',
    meta: {
      title: '审批详情',
      parentPath: '/cooperativeOfficeOA/paymentApprover',
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/children/approvalDetails.vue')
  },
  {
    path: '/salesContractManagement/makeOutInvoice',
    name: 'makeOutInvoice',
    meta: {
      title: '开票提交',
      parentPath: '/salesContractManagement/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/generationMining/children/makeOutInvoice.vue')
  },
  {
    path: '/purchasePlanManage/index',
    name: 'purchasePlanManageIndex',
    component: () => import('@/views/generationMining/purchasePlanManage.vue'),
    meta: {
      title: '采购计划管理',
      parentPath: '/purchasePlanManage/index',
      keepAlive: true,
      requireAuth: true
    }
  }, {
    path: '/financialManage/billingManage',
    name: 'billingManage',
    component: () => import('@/views/generationMining/billingManage.vue'),
    meta: {
      title: '开票管理',
      keepAlive: true,
      parentPath: '/financialManage/billingManage',
      requireAuth: true
    }
  }, {
    path: '/financialManage/billingDetails',
    name: 'billingDetails',
    component: () => import('@/views/generationMining/children/billingDetails.vue'),
    meta: {
      title: '开票详情',
      parentPath: '/financialManage/billingManage',
      requireAuth: true
    }
  },
  {
    path: '/purchasePlanManage/purchasePlanInfo',
    name: 'purchasePlanInfo',
    component: () => import('@/views/generationMining/children/purchasePlanInfo.vue'),
    meta: {
      title: '采购计划详情',
      parentPath: '/purchasePlanManage/index',
      requireAuth: true
    }
  }
]
export default generationMiningRoute
